@import url('https://fonts.googleapis.com/css?family=Poppins');

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.625;
  color: #666;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.625;
  color: #63A5B3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 1.625;
  color: #666;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 25px;
}
h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.625;
  color: #666;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  text-align: center;
  padding-right: 25px;
  padding-bottom: 10px;
}
.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.leftAlign {
  text-align: left;
}

.rightAlign {
  text-align: right;
}

.topAlign {
  vertical-align: top;
}

.bottomAlign {
  vertical-align: bottom;
}

.width100pct {
  width: 100%;
}

.width95pct {
  width: 95%;
}

.width80pct {
  width: 80%;
}

.width50pct {
  width: 50%;
}

.width33pct {
  width: 33%;
}

.hidden {
  display:none;
}

.tab {
  border: black solid 1px;
  color: black;
  background-color: silver;
  text-align: center;
}

.tab:hover {
  border: black solid 2px;
  color: white;
  background-color: blue;
}

.tabclicked {
  border: black solid 2px;
  color: white;
  background-color: blue;
  text-align: center;
}

.outline {
  border:black solid 1px;
}

